@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.db-nav-bar{
    position: relative;
    padding: 15px;
    border-radius: 10px;
    background: $text-color;
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    transition: 0.3s;
    a{
        text-decoration: none;
        text-transform: uppercase;
        color: $white;
        transition: 0.3s;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        &:hover{
            color: $secondary-color;
        }
        &[href="#jump-to-merch"] {
            margin-left: 10%;
        }
    }
    img{
        height: 10rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .db-menu-icon{
        position: absolute;
        top: 5%;
        right: -30%;
        z-index: 500;
        color: #F2792C;
        font-size: 2rem;
        cursor: pointer;
        display: none;
    }
}

@media screen and (max-width: 900px) {
    .db-nav-bar{
        
        a{
            width: 100%;
        }
        img{
            height: 7rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .db-nav-bar{
        position: absolute;
        top: 0;
        left: -50%;
        height: 100%;
        width: 50%;
        border-radius: 0px;
        display: block;
        a{
            display: block;
            margin-top: 2rem;
            &:first-child{
                margin-top: 5rem;
            }
        }
        img{
            display: none;
        }
        .db-menu-icon{
            display: block;
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import './FooterSocialIcons.scss';
$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;
$form-border: #aaaaaa;

.db-footer-section{
    height: 400px;
    background-color: $secondary-color;
    overflow: hidden;
    .footer-white-ball{
        position: absolute;
        right: -3%;
        bottom: -18%;
        img{
            height: 300px;
        }
    }
    .db-footer-section-content{
        width: 80%;
        height: 80%;
        position: relative;
        img{
            height: 180px;
        }
        h5{
            font-family: 'Jersey M54', sans-serif;
            font-size: 3rem;
            font-weight: 300;
            letter-spacing: 6px;
            color: $white;
        }
        p{
            color: $white;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

@media screen and (max-width:500px) {
    .db-footer-section{
        height: 800px;
        text-align: center;
        .footer-white-ball{
            display: none;
        }
        .db-footer-section-content{
            height: 60%;
            img{
                height: 100px;
            }
            p{
                position: relative;
                margin-top: 2rem;
            }
        }
    }
}
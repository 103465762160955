$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;


.custom-container{
    max-width: 1280px;
    margin: 0 auto;
    z-index: 500;
}

.db-header-section{
    height: 800px;
    background-image: url("../../assets/db-hero-image.png");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(88,88,88,0.8);
        z-index: 1;
    }
    .db-header-container{
        height: 600px;
        .db-header-content{
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            h1{
                text-transform: uppercase;
                text-align: center;
                font-family: 'Jersey M54', sans-serif;
                font-size: 8rem;
                width: 70%;
                font-weight: bolder;
                color: $white;
            }
        }
    }
    .db-mobile-logo{
        position: absolute;
        top: 5%;
        right: 5%;
        z-index: 500;
        display: none;
        img{
            height: 5rem;
        }
    }
}

@media screen and (max-width: 900px) {
    .db-header-section{
        height: 600px;
        position: relative;
        .db-header-container{
            height: 500px;
            .db-header-content{
                h1{
                    font-size: 5rem;
                    width: 60%;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .db-header-section{
        height: 600px;
        position: relative;
        .db-header-container{
            height: 500px;
            .db-header-content{
                h1{
                    font-size: 5rem;
                    width: 100%;
                }
            }
        }
        .db-mobile-logo{
            display: block;
        }
    }
}
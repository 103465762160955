
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.award-container{
    width: 80%;
    margin: 0 auto;
}
.db-awards-section{
    height: 550px;
    .db-awards-section-content{
        background: $primary-color;
        .award-image-container{
            position: relative;
            &::before{
                content: url("../../assets/zig-zag/zig-zag-orange-horizontal.png");
                position: absolute;
                top: 0;
                left: 0;
            }
            img{
                width: 100%;
                object-fit: cover;
                position: absolute;
                bottom: 0;
            }
        }
        .award-text-container{
            p{
                font-weight: bold;
            }
        }
    }
    .orange-box{
        height: 60px;
        width: 60px;
        background: $secondary-color;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .awards-content-container{
        h2{
            text-transform: uppercase;
            font-family: 'Jersey M54', sans-serif;
            font-size: 6rem;
            color: $primary-color;
            padding-right: 3rem;
            .award-content-arrow{
                margin-right: 2rem;
                img{
                    height: 3rem;
                    animation: leftMove 1.5s infinite;
                }
            }
        }
    }
}

@keyframes leftMove {
    0%{
        opacity: 1;
        transform: translateX(10px);
    }
    50%{
        opacity: 0;
        transform: translateX(15px);
    }
    100%{
        opacity: 1;
        transform: translateX(10px);
    }
}

@media screen and (max-width:500px) {
    .db-awards-section{
        height: auto;
        .db-awards-section-content{
            padding: 5rem 0;
                .award-image-container{
                    &::before{
                        content: "";
                    }
                    img{
                        position: relative;
                    }
                }
                .award-content-arrow{
                    display: none;
                }
        }
        .orange-box{
            display: none;
        }
    }
}
$primary-color: #7030A0;
$white: #ffffff;

.footer-social-icons-container{
    z-index: 500;
    .social-icon{
        display: inline-flex;
        flex-direction: column;
        width: 2.3rem;
        height: 2.3rem;
        background-color: $white;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1rem;
        margin: 0 0.5rem;
        color: $primary-color;
        transition: 0.3s;
        &:hover{
            background: $primary-color;
            color: $white;
        }
    }
}

@media screen and (max-width: 500px) {
    .footer-social-icons-container{
        .social-icon{
            width: 2rem;
            height: 2rem;
            font-size: 0.9rem;
        }
    }
}
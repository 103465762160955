$primary-color: #7030A0;
$white: #ffffff;

.social-icons-container{
    z-index: 500;
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    .social-icon{
        display: flex;
        flex-direction: column;
        width: 2.3rem;
        height: 2.3rem;
        background-color: $white;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 1rem;
        margin: 1rem 0;
        color: $primary-color;
        transition: 0.3s;
        &:hover{
            background: $primary-color;
            color: $white;
        }
    }
}

@media screen and (max-width: 900px) {
    .social-icons-container{
        top: 85%;
        left: 50%;
        transform: translateX(-35%);
        .social-icon{
            display: inline-flex;
            margin-left: 10px;
            width: 2rem;
            height: 2rem;
            font-size: 0.9rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .social-icons-container{
        width: 100%;
        top: 85%;
        left: 50%;
        // transform: translateX(-15%);
        .social-icon{
            display: inline-flex;
            margin-left: 10px;
            width: 2rem;
            height: 2rem;
            font-size: 0.9rem;
        }
    }
}
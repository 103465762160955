
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.db-merch-items{
    height: 700px;
    background: $white;
    .db-merch-items-btn{
        margin-top: 5rem;
        button{
            font-family: 'Roboto', sans-serif;
            outline: none;
            background-color: $optional-color;
            color: $white;
            padding: 6px 20px;
            font-weight: 500;
            border-radius: 10px;
            border: 2px solid $optional-color;
            transition: 0.3s;
            cursor: pointer;
            &:hover{
                background-color: $white;
                color: $optional-color;
            }
        }
    }
    .merch-item-container{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            height: 170px;
            width: 170px;
            background: $optional-color;
            bottom: -9%;
            left: -2%;
        }
        img{
            width: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 500px){
    .db-merch-items{
        height: auto;
        padding: 2rem 0;
        .merch-item-container{
            &::before{
                display: none;
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.custom-container{
    width: 85%;
    margin: 0 auto;
    z-index: 500;
}

.db-about-section{
    height: 800px;
    .about-content-container{
        .about-content{
            width: 80%;
            position: relative;
            &::before{
                content: url("./striped_rec.svg");
                height: 50%;
                position: absolute;
                left: -65%;
                top: 0;
            }
            h2{
                font-size: 5rem;
                text-transform: uppercase;
                font-family: 'Jersey M54', sans-serif;
                color: $primary-color;
            }
            p{
                font-family: 'Roboto', sans-serif;
                color: $text-color;
                margin-top: 2rem;
            }
            a{
                text-decoration: none;
                padding: 9px 30px; 
                background-color: #7030A0;
                color: #ffffff;
                text-transform: capitalize;
                font-weight: 600;
                border-radius: 10px;
                border: 2px solid $primary-color;
                transition: 0.3s;
                &:hover{
                    background: $white;
                    color: $primary-color;
                }
            }
            .arrow-container{ 
                img{
                    height: 2.5rem;
                    margin-left: 2rem;
                    margin-top: -2%;
                    animation: move 1s infinite;
                }
            }
        }
    }
    .about-outline-container{
        height: 45%;
        position: relative;
        z-index: 1;
        img{
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);
            width: 60%;
        }
    }
    .about-team-container{
        height: 55%;
        width: 100%;
        z-index: 500;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        // background-image: url("../../assets/about-team.png");
        // background-size: cover;
        // background-repeat: no-repeat;
    }
}

@keyframes move {
    0%{
        opacity: 1;
        transform: translateX(-10px);
    }
    50%{
        opacity: 0;
        transform: translateX(-15px);
    }
    100%{
        opacity: 1;
        transform: translateX(-10px);
    }
}

@media screen and (max-width:500px) {
    .db-about-section{
        height: auto;
        .about-content{
            width: 100%;
            padding: 5rem 0.5rem 5rem 3rem;
            h2{
                font-size: 3rem;
            }
            .arrow-container{
               display: none;
            }
        }
        .about-outline-container{
            height: 100%;
            img{
                width: 80%;
            }
        }
        .about-team-container{
            height: auto;
        }
    }
}
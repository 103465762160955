
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.custom-container{
    max-width: 1280px;
    margin: 0 auto;
    z-index: 500;
}

.db-events-section{
    height: 500px;
    .db-events{
        height: 100%;
        background: $white;
        .db-events-headline{
            h2{
                font-size: 5rem;
                font-family: 'Jersey M54', sans-serif;
                text-transform: uppercase;
            }
            .events-heading{
                color: $secondary-color;
            }
            .events-baller-heading{
                color: $white;
                -webkit-text-stroke: 3px $optional-color;
            }
            img{
                height: 40px;
                animation: move 1.5s infinite;
            }
        }
        .db-events-content{
            background-color: $secondary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                color: $white;
            }
            button{
                text-transform: capitalize;
                font-family: 'Roboto', sans-serif;
                outline: none;
                background-color: $white;
                color: $secondary-color;
                padding: 6px 20px;
                font-weight: 500;
                border-radius: 10px;
                border: 2px solid $white;
                transition: 0.3s;
                a{
                    color: #F2792C;
                    text-decoration: none;
                }
                &:hover{
                    background-color: $secondary-color;
                    a{
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@keyframes move {
    0%{
        opacity: 1;
        transform: translateX(-10px);
    }
    50%{
        opacity: 0;
        transform: translateX(-15px);
    }
    100%{
        opacity: 1;
        transform: translateX(-10px);
    }
}

@media screen and (max-width: 500px) {
    .db-events-section{
        height: auto;
        .db-events{
            height: auto;
            .db-events-headline{
                margin-top: 10rem;
                height: 500px;
                padding-top: 5rem;
                h2{
                    font-size: 4rem;
                }
                img{
                    display: none;
                }
            }
            .db-events-content{
                height: auto;
                padding: 5rem 1rem;
            }
        }
    }
}
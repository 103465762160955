
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;

.db-merch-header{
    height: 500px;
    padding-bottom: 3rem;
    .orange-ball{
        position: absolute;
        bottom: 0;
        right: -4%;
        img{
            height: 10rem;
        }
    }
    .merch-green-box{
        height: 170px;
        width: 150px;
        background-color: $optional-color;
        position: absolute;
        left: -4%;
        top: -8%;
        z-index: -1;
    }
    .merch-header-banner{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            width: 170px;
            height: 170px;
            background: $optional-color;
            top: -9%;
            left: -2%;
            z-index: -1;
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .merch-header-footer{
        .merch-header-footer-image{
            position: absolute;
            left: 0;
            top: 0;
            img{
                height: 14rem;
            }
        }
        .merch-header-footer-content{
            .merch-header-footer-dist{
                position: absolute;
                bottom: 0;
                right: 0;
                h2{
                    font-family: 'Jersey M54', sans-serif;
                    color: $optional-color;
                    font-size: 5rem;
                    text-align: right;
                    .green-arrow-down{
                        margin-right: 2rem;
                        img{
                            height: 3rem;
                            animation: downMove 1.5s infinite;
                        }
                    }
                }
            }
        }
    }
}

@keyframes downMove {
    0%{
        opacity: 1;
        transform: translateY(10px);
    }
    50%{
        opacity: 0;
        transform: translateY(15px);
    }
    100%{
        opacity: 1;
        transform: translateY(10px);
    }
}

@media screen and (max-width:500px) {
    .db-merch-header{
        height: auto;
        margin-top: 2rem;
        .orange-ball{
            display: none;
        }
        .merch-green-box{
            display: none;
        }
        .merch-header-footer{
            height: 300px;
            padding: 2rem 0;
            .merch-header-footer-image{
                display: none;
            }
        }
    }
}
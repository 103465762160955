
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$primary-color: #7030A0;
$secondary-color: #F2792C;
$text-color: #262626;
$optional-color: #00B050;
$white: #ffffff;
$form-border: #aaaaaa;

.db-subscribe-section{
    height: 800px;
    .subscribe-form{
        .orange-box{
            width: 60px;
            height: 60px;
            background-color: $secondary-color;
            position: absolute;
            top: 0;
            right: 0;
        }
        .push-notification{
            position: absolute;
            padding: 1rem 2rem;
            background-color: $secondary-color;
            color: $white;
            border-radius: 15px;
            box-shadow: 10px 10px 10px rgba(0,0,0,0.1);
            top: 50%;
            animation: crossMove 0.3s;
            transition: 0.3s;
            .cross{
                font-size: 24px;
                cursor: pointer;
            }
            h2{
                font-weight: bold;
            }
        }
        .violate-zig-zag{
            position: absolute;
            bottom: 0;
            right: 0;
            img{
                height: 18rem;
            }
        }
        .subscribe-form-content{
            width: 550px;
            padding: 2rem;
            h2{
                font-size: 5rem;
                text-transform: uppercase;
                font-family: 'Jersey M54', sans-serif;
                color: $primary-color;
            }
            p{
                color: $secondary-color;
            }
            input{
                outline: none;
                border: 2px solid $form-border;
                padding: 12px 16px;
                width: 100%;
                color: $text-color;
                border-radius: 3px;
            }
            textarea{
                width: 100%;
                outline: none;
                border: 2px solid $form-border;
                padding: 12px 16px;
                height: 150px;
                color: $text-color;
                border-radius: 3px;
            }
            button{
                outline: 0;
                border: 0;
                background-color: $secondary-color;
                color: $white;
                border-radius: 10px;
                padding: 8px 30px;
                margin-top: 2rem;
            }
        }
    }
    .subscribe-form-image{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@keyframes crossMove {
    from{
        transform: translate(-300%);
    }
    to{
        transform: translate(0%);
    }
}

@media screen and (max-width:500px) {
    .db-subscribe-section{
        height: auto;
        .subscribe-form{
            .violate-zig-zag{
                display: none;
            }
            .subscribe-form-content{
                h2{
                    font-size: 4rem;
                }
            }
        }
    }
}